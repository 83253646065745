@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&display=swap");

:root {
  --padding-amount: 200px;
}

@font-face {
  font-family: "Cageroll-Cage";
  src: url("./Cageroll-Cage.otf") format("opentype");
}

body {
  margin: 0;
  background: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  border-radius: 20px;
}

.headerArea {
  text-align: center;
  justify-content: center;
  display: grid;
  grid-row: 2;
  grid-column: 1;
  margin-top: 50px;
  padding-top: 50px;
  /* height: 500px; */
  width: 100%;
  place-items: center;
}

.headerArea img {
  text-align: center;
  z-index: 1;
  grid-column-start: 1;
  grid-row-start: 2;
  width: 80%;
}

.headerArea h1 {
  font-family: "Cageroll-Cage", sans-serif;
  color: #adff2f; /* #CCFF00 */
  font-weight: 800;
  font-size: 10rem;
  vertical-align: top;
  z-index: 0;
  grid-column-start: 1;
  grid-row-start: 1;
}

.photoCardWrapper {
  display: grid;
  grid-template-columns: 40% 40%;
  place-items: center;
  justify-content: center;
}

.photoCard {
  height: 100%;
  padding-top: var(--padding-amount);
  padding-bottom: 32px;
}

.photoCard:hover {
  cursor: pointer;
}

.photoCardText a:link,
a:visited,
a:hover,
a:active {
  color: white;
}

.photoCardText {
  background: none;
  color: white;
  font-size: 1rem;
  border: none;
  padding: 0;
  padding-top: 7px;
  outline: inherit;
}

.photoCardText button {
  font-family: "Noto Sans Mono", monospace;
  background: none;
  border: none;
  outline: inherit;
  color: white;
  text-decoration: underline;
}

.headerNav {
  display: grid;
}

.headerNavTitle {
  font-family: "Noto Sans Mono", monospace;
  grid-column-start: 1;
  padding-top: 32px;
  padding-left: 32px;
  color: white;
  text-decoration: none;
}

.headerNavPlaylist {
  grid-column-start: 2;
  font-family: "Noto Sans Mono", monospace;
  justify-self: center;
  padding-top: 32px;
  padding-right: 32px;
  background: none;
  color: white !important;
  border: none;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.headerNavPlaylist a {
  text-decoration: none;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  place-items: center;
}

.headerNavAbout {
  grid-column-start: 3;
  font-family: "Noto Sans Mono", monospace;
  justify-self: right;
  padding-top: 32px;
  padding-right: 32px;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  outline: inherit;
}

.aboutDivWrapper {
  overflow: hidden;
  position: relative;
}

.aboutDiv {
  position: absolute;
  right: -50px;
  top: 50px;
  width: 500px;
  height: 500px;
  background-color: white;
}

.visibleClass {
  overflow: hidden;
  opacity: 1;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  display: grid;
  grid-template-rows: 75% 25%;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  text-align: center;
}

.visibleClass img {
  grid-row: 2 / span 1;
  grid-column: 1 / span 10;
  align-self: center;
  justify-self: center;
}

.hiddenClass {
  opacity: 0;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  display: grid;
  grid-template-rows: 50% 25% 25%;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

.closeAboutDivButton {
  background: none;
  color: white;
  font-size: 2rem;
  font-weight: 400;
  border: none;
  padding: 0;
  width: 5rem;
  height: 4rem;
  justify-self: right;
  margin-top: 32px;
  margin-right: 32px;
  outline: inherit;
  grid-column: 10 / span 1;
  grid-row: 1 / span 1;
}

.aboutDiv {
  font-family: "Noto Sans Mono", monospace;
  font-size: 2.3rem;
  grid-column: 1 / span 9;
  grid-row: 1 / span 1;
  width: 100%;
  background: black;
  color: white;
  line-height: 3.5rem;
  letter-spacing: 1px;
  font-weight: 400;
}

.instagramLink {
  font-family: "Noto Sans Mono", monospace;
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  justify-self: center;
  align-self: end;
  padding-bottom: 32px;
  position: sticky;
  font-size: 1.8rem;
  color: white !important;
}

.emailLink {
  font-family: "Noto Sans Mono", monospace;
  grid-column: 7 / span 4;
  grid-row: 2 / span 1;
  justify-self: center;
  align-self: end;
  padding-bottom: 32px;
  position: sticky;
}

.letterboxdLink {
  font-family: "Noto Sans Mono", monospace;
  grid-column: 4 / span 3;
  grid-row: 2 / span 1;
  justify-self: center;
  align-self: end;
  padding-bottom: 32px;
  position: sticky;
  font-size: 1.8rem;
  color: white !important;
}

.emailLink button {
  background: none;
  border: none;
  outline: inherit;
  color: white;
  text-decoration: underline;
  font-size: 1.8rem;
}

.emailLink img {
  width: 500px;
  height: 500px;
  z-index: 500;
}

.visibleAlbumClass {
  overflow: hidden;
  opacity: 1;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 30% 40% 30%;
  pointer-events: auto;
}

.hiddenAlbumClass {
  opacity: 0;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
}

.albumNavTitle {
  font-family: "Exo", sans-serif;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  padding-top: 32px;
  padding-left: 32px;
  color: white;
  text-decoration: none;
}

.closeAlbumDivButton {
  background: none;
  color: white;
  font-size: 2rem;
  border: none;
  padding: 0;
  width: 4rem;
  height: 4rem;
  justify-self: right;
  margin-top: 32px;
  margin-right: 32px;
  outline: inherit;
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
}

.leftArrow {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  padding-left: 20%;
  font-size: 2rem;
  color: white;
  justify-self: left;
  align-self: center;
}

.leftArrow:hover {
  cursor: pointer;
}

.rightArrow {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  padding-right: 20%;
  font-size: 2rem;
  color: white;
  justify-self: right;
  align-self: center;
}

.rightArrow:hover {
  cursor: pointer;
}

.albumPhotoCardWrapper {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  text-align: center;
  grid-template-rows: 65% 35%;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

.albumPhotoCard {
  display: grid;
  grid-template-rows: 5% 95%;
  grid-template-columns: 1fr min-content;
  grid-row: 1 / span 1;
  z-index: 100;
  grid-column: 1 / span 10;
}

.cameraName {
  font-family: "Noto Sans Mono", monospace;
  color: white;
  grid-row: 2 / span 1;
  grid-column: 2 / span 2;
  text-orientation: mixed;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  align-self: end;
  justify-self: auto;
}

.albumPhotoCardText {
  font-family: "Noto Sans Mono", monospace;
  color: white;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  z-index: 200;
  justify-self: start;
}

.albumPhotoMain {
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  align-self: center;
  justify-self: center;
  max-height: 100%;
}

.carousel-container {
  display: flex;
  align-items: center;
  width: 600px;
  z-index: 1000;
}

.carouselButton {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.carouselButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.carousel {
  grid-row: 2 / span 1;
  grid-column: 1 / span 10;
  align-content: center;
  width: 100%;
  overflow: hidden;
  margin: 0 10px;
  z-index: 1000;
}

.carousel-inner {
  display: flex;
  width: 20000px;
  transition: transform 0.3s ease;
  z-index: 1000;
}

.thumbnail {
  margin-left: 5px;
  margin-right: 5px;
  width: 100px;
  max-height: 60px;
  z-index: 1000;
  cursor: pointer;
}

.thumbnail:hover {
  filter: brightness(150%);
}

.sticky-accent-image {
  position: sticky;
  top: 1000px; /* Adjust the position as needed */
  z-index: -1; /* Places the image behind other elements */
  opacity: 0.7; /* Faded effect */
  width: 100px; /* Adjust the size as needed */
  height: auto;
}

@media (max-width: 500px) {
  .headerArea h1 {
    padding-top: 50px;
    font-size: 3rem;
  }

  .photoCard {
    height: 100%;
    padding-top: calc(var(--padding-amount) / 2.5);
    padding-bottom: 0px;
    padding-left: 10px;
  }

  .photoCardWrapper {
    padding-top: 10px;
  }

  .aboutDiv {
    font-size: 0.8rem;
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
    line-height: 1rem;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .closeAboutDivButton {
    background: none;
    color: white;
    font-size: 2rem;
    font-weight: 400;
    border: none;
    padding: 0;
    width: 5rem;
    height: 4rem;
    justify-self: right;
    margin-top: 10px;
    margin-right: 10px;
    outline: inherit;
    grid-column: 10 / span 1;
    grid-row: 1 / span 1;
  }

  .visibleClass img {
    width: 100%;
  }

  .visibleAlbumClass {
    opacity: 1;
    background: black;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    display: grid;
    grid-template-rows: 10% 90%;
    grid-template-columns: 20% 60% 20%;
    pointer-events: auto;
  }

  .albumPhotoCardWrapper {
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
    display: grid;
    text-align: center;
    grid-template-rows: 65% 35%;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  }

  .leftArrow {
    z-index: 200;
  }

  .rightArrow {
    z-index: 200;
  }

  .headerArea {
    margin-top: 0px;
    padding-top: 0px;
    height: 300px;
  }

  .instagramLink {
    position: relative;
    font-size: 1rem;
  }

  .emailLink {
    position: relative;
    font-size: 1rem;
  }

  .letterboxdLink {
    font-size: 1rem;
  }

  .emailLink button {
    font-size: 1rem;
  }

  .closeAlbumDivButton {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    justify-self: right;
    margin-top: 10px;
    margin-right: 10px;
    outline: inherit;
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }

  .albumPhotoMain {
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
    align-self: center;
    justify-self: center;
    max-height: 100%;
  }

  .leftArrow {
    display: none;
  }

  .rightArrow {
    display: none;
  }

  .thumbnail {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    max-height: 20%;
    position: relative;
    z-index: 1000;
  }
}

@media (max-width: 1600px) and (min-width: 500px) {
  .aboutDiv {
    font-size: 1.5rem;
  }
}

footer {
  margin-top: 32px;
  font-family: "Noto Sans Mono", monospace;
  display: grid;
  justify-content: center;
  font-size: 16px;
}

footer a {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
